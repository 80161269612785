import { z } from "zod"

import type { DropdownItem } from "#ui/types"
import {
  BaseContractSchema,
  ContractSchema,
  DocKind,
  GSKpiLevel,
  GSRefreshModel,
  GaugeModel,
  GroupGSKpi,
  GroupGSKpiUuid,
  InvoiceSchema,
  InvoiceSchemaShort,
  SiteContractStatus,
  SiteGSKpi,
  SiteSchema,
  SiteSchemaOverview,
  SubGroupGSKpi,
  SubGroupGSKpiUuid,
  User,
  WindTurbineSchema,
  SolarFieldSchema,
  HydroTurbineSchema,
  CogenerationSchema,
} from "~/composables/codegen/api-client"
import type { MetricSchema as BaseMetricSchema } from "~/composables/codegen/api-client"

// _______ General _______

export const Unit = z.enum([
  // Energy
  "TWh",
  "GWh",
  "MWh",
  "kWh",
  //
  // Reactive Power
  "kVA",
  "V",
  //
  // Irradiance
  "W/m²",
  "Wh/m²",
  // Power
  "TW",
  "GW",
  "MW",
  "kW",
  "W",
  //
  // Prices
  "eur/kW",
  "eur/MW",
  "eur/kWh",
  "eur/MWh",
  "ceur/kWh",
  //
  // Currency
  "ceur", // centi euro
  "eur",
  "keur", // kilo euro -> milliers
  "Meur", // mega euro -> million
  "Geur", // giga euro -> milliard
  //
  // Distance
  "m", // meter
  //
  // Special
  "dimensionless",
  "eur/dimensionless",
  "%",
  "latlng",
  "coef_l",
])
export type Unit = z.infer<typeof Unit>

export const Freq = z.enum(["1YS", "1MS", "1W-MON", "1D", "1H", "30T", "15T", "10T", "5T"])
export type Freq = z.infer<typeof Freq>

// _______ Backend Validation error  _______
export const BackendValidationErrorDetail = z.object({
  loc: z.string().array(),
  msg: z.string(),
})

export type BackendValidationErrorDetail = z.infer<typeof BackendValidationErrorDetail>

export const BackendValidationError = z.object({ detail: BackendValidationErrorDetail.array() })
export type BackendValidationError = z.infer<typeof BackendValidationError>

// _______ Timeseries Models _______

export const TimeSeries = z.object({
  name: z.string(),
  index: z.array(z.coerce.date().or(z.number())),
  data: z.array(z.number().nullable()),
})
export type TimeSeries = z.infer<typeof TimeSeries>

export const DataFrame = z.object({
  columns: z.array(z.string()),
  index: z.array(z.coerce.date().or(z.number())),
  data: z.array(z.array(z.number().nullable())),
})
export type DataFrame = z.infer<typeof DataFrame>

// _______ Invoice _______

export const InvoiceActionCategory = z.enum([
  "download",
  "link",
  "base",
  "submit",
  "upload",
  "edit",
  "cancel",
])
export type InvoiceActionCategory = z.infer<typeof InvoiceActionCategory>

export const InvoiceActionId = z.enum([
  "download_pdf",
  "download_excel",
  "view_invoice",
  "view_site",
  "view_contract",
  "compute",
  "submit",
  "publish",
  "publish_draft",
  "unpublish",
  "reject",
  "void_and_replace",
  "credit_note",
  "delete",
  "submit_to_zero",
  "edit",
  "set_as_submitted",
  "upload_bonus_malus",
  "set_as_paid",
])
export type InvoiceActionId = z.infer<typeof InvoiceActionId>
export interface InvoiceActionItem extends DropdownItem {
  action: InvoiceActionId
}

// _______ Metric _______

export const MetricName = z.enum([
  "contracted_capacity",
  "data_health",
  "financial_report",
  "production_report",
  "power_production",
  "revenue",
  "incident_energy_when_energy",
  "availability_rate",
  "trs",
  "pr",
  "portfolio_health",
])
export type MetricName = z.infer<typeof MetricName>

export const MetricModel = z.enum(["assetmanager", "assetowner", "holding", "site"])
export type MetricModel = z.infer<typeof MetricModel>

export const MetricHorizon = z.enum([
  "ytd",
  "yt_last_month",
  "yt_quarter",
  "last_year",
  "rolling_year",
  "current_month",
  "last_month",
  "rolling_month",
  "current_week",
  "last_week",
  "rolling_week",
  "today",
  "yesterday",
])
export type MetricHorizon = z.infer<typeof MetricHorizon>

export type MetricSchema<T = any> = Prettify<
  Omit<BaseMetricSchema, "value"> & { value: T | undefined | null }
>
export type ContractedCapacityMetric = MetricSchema<Partial<Record<SiteContractStatus, TimeSeries>>>

export const AvailabilityType = z.enum(["injection", "detailed", "ir_prorated"])
export type AvailabilityType = z.infer<typeof AvailabilityType>

export interface AvailabilityGauge {
  last_data: string | Date | null
  status: "good" | "late" | "missing" | null
}

export interface ConsistencyGauge {
  consistent: boolean
  coverage: number
  difference: number
}

export interface DataHealthFieldInfo {
  tags: TSTag[]
  sprovider: { name: string; code: string } | null
  freq: Freq
  unit: Unit
}

export interface DataHealthField {
  availability: {
    [key: string]: AvailabilityGauge
  }
  completeness: {
    [key: string]: GaugeModel
  }
  consistency: {
    [key: string]: ConsistencyGauge
  }
  infos: {
    [key: string]: DataHealthFieldInfo
  }
  reference: string
}

//

export type * from "./models"
export type * from "./client"

export const ProducerCompanyKind = z.enum(["assetmanager", "assetowner", "holding"])
export type ProducerCompanyKind = z.infer<typeof ProducerCompanyKind>

export const NoteQueryModelName = z.enum([
  "site",
  "assetmanager",
  "assetowner",
  "holding",
  "contract",
  "portfolio",
])
export type NoteQueryModelName = z.infer<typeof NoteQueryModelName>

export const NoteTagLevel = z.enum(["info", "warning", "error", "action"])
export type NoteTagLevel = z.infer<typeof NoteTagLevel>

export const ReportTargetModel = z.enum(["assetmanager", "assetowner", "holding", "site"])
export type ReportTargetModel = z.infer<typeof ReportTargetModel>

export const ReportTargetSchema = z.object({
  uuid: z.string().uuid(),
  model: ReportTargetModel,
  name: z.string(),
})
export type ReportTargetSchema = z.infer<typeof ReportTargetSchema>

export const GSRefreshTargetSchema = z.object({
  uuid: z.string().uuid(),
  model: GSRefreshModel,
  name: z.string(),
})
export type GSRefreshTargetSchema = z.infer<typeof GSRefreshTargetSchema>

export const BudgetScope = z.enum(["budget", "business_plan", "bank"])
export type BudgetScope = z.infer<typeof BudgetScope>

export const GSCategories = z.enum([
  "realtime",
  "metering",
  "operation",
  "revenue",
  "budget",
  "self_consumption",
])
export type GSCategories = z.infer<typeof GSCategories>

// GSMetricName from zodios is z.unknown for now... So type it ourselves
export const GSMetricName = z.enum([
  "revenue",
  "production",
  "availability_injection",
  "availability_detailed",
  "availability_energy_prorated",
  "availability_technical",
  "trs",
  "trs_budget",
  "trs_budget_recalled",
  "pr",
  "pr_budget",
  "pr_budget_recalled",
  "self_consumption_rate",
  "self_production_rate",
  "coverage_rate",
])
export type GSMetricName = z.infer<typeof GSMetricName>

export const GSFreq = z.enum([Freq.enum["1MS"], Freq.enum["1D"]])
export type GSFreq = z.infer<typeof GSFreq>

// _______ DataHealth _______

export const DHStatus = z.enum(["good", "warning", "critical", "missing"])
export type DHStatus = z.infer<typeof DHStatus>

export const DHCommentLevel = z.enum(["error", "info", "aggregated"])
export type DHCommentLevel = z.infer<typeof DHCommentLevel>

export type InvoiceComputeTarget =
  | BaseContractSchema
  | ContractSchema
  | SiteSchemaOverview
  | SiteSchema
  | InvoiceSchemaShort
  | InvoiceSchema

// Missing distiction in backend for DocKind which have are document generated
export const DocGenKind = z.enum([
  DocKind.enum.finance_report,
  DocKind.enum.operation_report,
  DocKind.enum.performance_test_report,
  DocKind.enum.pr_report,
  DocKind.enum.cumulative_index_report,
  DocKind.enum.data_extract,
  DocKind.enum.invoice_lines_report,
  DocKind.enum.invoices_accounting_report,
  DocKind.enum.contract_data_extract,
  DocKind.enum.partner_extract,
  DocKind.enum.finance_landing_extract,
])

export type DocGenKind = z.infer<typeof DocGenKind>

export const DocUploadKind = z.enum([DocKind.enum.contract, DocKind.enum.invoice_bonus_malus])
export type DocUploadKind = z.infer<typeof DocUploadKind>

export const GSKpiAggregationCat = z.enum(["company", "region", "technology"])
export type GSKpiAggregationCat = z.infer<typeof GSKpiAggregationCat>

export const GSKpi = z.union([
  SiteGSKpi,
  GroupGSKpiUuid,
  SubGroupGSKpiUuid,
  GroupGSKpi,
  SubGroupGSKpi,
])
export type GSKpi = z.infer<typeof GSKpi>
export const CompanyGSKpi = GroupGSKpiUuid.extend({
  target_model: z.enum([GSKpiLevel.enum.assetowner, GSKpiLevel.enum.holding]),
})
export type CompanyGSKpi = z.infer<typeof CompanyGSKpi>

export const Scope = User.shape.permissions.element.options[0]
export type Scope = z.infer<typeof Scope>

export type DropdownItemScope = DropdownItem & { scope?: Scope }

export const AssetSchema = z.union([
  WindTurbineSchema,
  SolarFieldSchema,
  HydroTurbineSchema,
  CogenerationSchema,
])
export type AssetSchema = z.infer<typeof AssetSchema>

export const InvoiceCancelActions = z.enum([
  "unpublish",
  "reject",
  "void_and_replace",
  "credit_note",
  "delete",
])
export type InvoiceCancelActions = z.infer<typeof InvoiceCancelActions>

export const ComparisonHorizonType = z.enum(["last_year", "preceding", "none"])
export type ComparisonHorizonType = z.infer<typeof ComparisonHorizonType>

// From Codegen Client:
export {
  PastillaSchema,
  PastillaName,
  HorizonLabel,
  PastillaStatus,
  DocumentSchemaShort,
  EventSchema,
  EventSchemaUpdate,
  EventType,
  EventCause,
  EventSource,
  DeliveryPointCodeKind,
  DeliveryPointType,
  DeliveryPointSchema,
  DeliveryPointSchemaUpdate,
  Segment as MeterSegment,
  EnedisNetworkCfg,
  DSOSchema,
  MachineSubType as MachineType,
  MachineType as MachineTechno,
  TurbineType as WindTurbineType,
  WindTurbineSchema,
  SolarMountingType,
  SolarTrackingType,
  OrientationReference,
  SolarFieldSchema,
  HydroType as HydroTurbineType,
  HydroTurbineSchema,
  CogeType as CogenerationType,
  CogenerationSchema,
  Inverter,
  Module,
  TechnicalData,
  SiteStatus,
  SiteContractStatus,
  PastillaSchemaShort,
  SiteSchema,
  SiteSchemaUpdate,
  SiteSchemaOverview,
  SiteSchemaShort,
  DocumentQueryModelName,
  DocKind,
  NoKwargsReport,
  MetricStatus,
  GaugeModel,
  GSGaugeMetric,
  SiteGSKpi,
  GroupGSKpi,
  GroupGSKpiUuid,
  SubGroupGSKpi,
  SubGroupGSKpiUuid,
  GSKpiLevel,
  GSKpiTechno,
  GSRefreshModel,
  GSDataCategory,
  DateRangeReportKwargs,
  DataExtractReportKwargs,
  CumIndexReportKwargs,
  OperationReportKwargs,
  FinanceReportKwargs,
  CompanySchemaShort,
  PartnerSummarySchema,
  ContactInfoSchema,
  ContractStatus,
  ContractTypeSchema,
  ClauseSchema,
  ClauseTypes,
  InvoicingCfg,
  InvoicingCfgDetails,
  InvoicingFreq,
  PriceMechanism,
  ContractStatusHistorySchema,
  BaseContractSchema,
  ContractSchema,
  ContractSummarySchema,
  ContractSchemaUpdate,
  User,
  InvoiceStatus,
  InvoiceSubmitChannel,
  InvoiceExternalStatus,
  InvoiceLineSchema,
  InvoiceLogAction,
  InvoiceSource,
  InvoiceSiteSchema,
  InvoiceContractSchema,
  InvoiceSchema,
  InvoiceSchemaShort,
  InvoiceSchemaUpdate,
  InvoiceComputationSchema,
  FilterKind,
  NoteTagSchema,
  NoteTagSchemaCreate,
  NoteTagSchemaUpdate,
  NoteSchema,
  NoteSchemaCreate,
  NoteSchemaUpdate,
  BaseDHSchema,
  DeliveryPointKind,
  SiteDHSchema,
  DHCategory,
  DHCheckKind,
  DHSchema,
  Way,
  MeasureType,
  IndexPriceFallback,
  NegativeHoursSource,
  DHFlagCode,
  CompanyKind,
  CommissioningDashboardTree,
  CommissioningSubgroup,
  CommissioningSubgroupName,
  CommissioningGroup,
  CommissioningGroupName,
  CommissioningSite,
  SiteStatusHistorySchema,
  SProviderData,
  TimeseriesGroup,
  TSAcquisitionGroup,
  TSSchema,
} from "~/composables/codegen/api-client"
